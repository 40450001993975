// QueryHandler.js
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AuthRepository from '../../domain/repositories/AuthRepository';


const QueryHandler = () => {
    const { customer_identifier } = useParams(); // Extract 'id' from the URL
    const navigate = useNavigate();
    const authRepository = new AuthRepository();


    useEffect(() => {
        if (customer_identifier) {
            // Save ID to local storage or use it for your logic
            authRepository.setCustomerIdentifier(customer_identifier)
            
  

            // Redirect to Home Page
            navigate('/');
        }
    }, [customer_identifier, navigate]);

    return (
        <div>
            <h2>Processing ID...</h2>
        </div>
    );
};

export default QueryHandler;
