import React, { useState, useEffect, useRef } from "react";

import Select from 'react-select';
import CompetitorRepository from '../../domain/repositories/CompetitorRepository';
import ParticipatesInRepository from '../../domain/repositories/ParticipatesInRepository';
import SectionRepository from '../../domain/repositories/SectionRepository';
import CertificateRepository from '../../domain/repositories/CertificateRepository';
import JobsRepository from '../../domain/repositories/JobsRepository';
import AuthRepository from '../../domain/repositories/AuthRepository';

import Certificate from '../../domain/models/CertificateModel';
import { getCurrentDateAndTimeinAuFormat } from '../../utils/dateUtils';
import { getRank } from '../../utils/commonUtils';
import Mapper from '../../domain/models/mapper/Mappers';
import Customer from "../../domain/models/CustomerModel";
import UnauthorizedError from '../../domain/models/UnauthorizedError'


import LoadingModal from './LoadingModal'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import '../../assets/css/table.css';
import { useNavigate } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file


const Home = ({ onLogout }) => {

  const navigate = useNavigate();

  const [jobStatusOptions, setJobStatusOptions] = useState([]);

  const [jobStatusSelectedOptions, setJobStatusSelectedOptions] = useState([]);



  const [jobStatusPlaceholder, setJobStatusPlaceholder] = useState('Job Status');
  const [jobStatusInputValue, setJobStatusInputValue] = useState('ALL');


  const [jobsData, setJobsData] = useState([]);

  const [customer, setCustomerData] = useState(new Customer());



  const [competitionTableFilter, setCompetitionTableFilter] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(false);


  const [isLoading, setIsLoading] = useState(true);


  const currentDate = new Date()

  const lastYearDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, currentDate.getDate());
  const nextYearDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 6, currentDate.getDate());


  const [startDate, setStartDate] = useState(lastYearDate.toLocaleDateString('en-AU')); // set to last year's date with today's month and year
  const [endDate, setEndDate] = useState(nextYearDate.toLocaleDateString('en-AU')); // set to today's date


  const [rankSelectedOption, setRankSelectedOption] = useState(1);
  const [rankOptions, setRankOptions] = useState(getRank());

  const [keyUsedToClearNameSelect, setKeyUsedToClearNameSelect] = useState(200);
  const [keyUsedToClearSectionSelect, setKeyUsedToClearSectionSelect] = useState(0);
  const [keyUsedToClearJobStatusSelect, setKeyUsedToClearJobStatusSelect] = useState(50);


  const [dateRange, setDateRange] = useState({
    startDate: currentDate,
    endDate: currentDate,
    key: 'selection'
  });

  const [showPicker, setShowPicker] = useState(false);


  const competitorRepository = new CompetitorRepository();
  const participatesInRepository = new ParticipatesInRepository();
  const sectionRepository = new SectionRepository();
  const certificateRepository = new CertificateRepository();
  const jobsRepository = new JobsRepository();
  const authRepository = new AuthRepository();

  const handleDateRangeChange = (ranges) => {
    setDateRange(ranges.selection);
  };

  const handleDateRangeDoneClick = () => {
    setShowPicker(false);
    const startDate = dateRange.startDate.toLocaleDateString();
    const endDate = dateRange.endDate.toLocaleDateString();
    setStartDate(startDate)
    setEndDate(endDate)
    setCompetitionTableFilter(!competitionTableFilter);
  };

  const handleDateRangeButtonClick = () => {
    setShowPicker(!showPicker);
  };



  const handleJobStatusSelectedOptions = jobStatusSelectedOptions => {
    // setJobStatusSelectedOptions(jobStatusSelectedOptions);


    const { value, label } = jobStatusSelectedOptions;
    setJobStatusInputValue(value);


    //fetch in participates table for call compeittion with competition id
    setCompetitionTableFilter(!competitionTableFilter);

  };



  useEffect(() => {




    async function testApiCall() {
      setIsLoading(true)
      try {
        const testApiCallResponse = await jobsRepository.getAllJobs();
        setJobsData(testApiCallResponse)


        console.log("Test Api Call Data", testApiCallResponse)
        setIsLoading(false);



      } catch (error) {
        console.error("Test Api Call Data Error:", error);
        toast.error(new Error(`${error}`).message);
        setIsLoading(false);

      }
    }

    async function getCustomerModelFromLocalStorage() {

      const customerModel = await authRepository.getCustomerModelFromLocalStorage();
      setCustomerData(customerModel)
      return customerModel;
    }

    async function fetchJobStatusOptions() {
      const jobsStatusData = await jobsRepository.getAllJobStatuses()
      setJobStatusOptions(jobsStatusData);
    }

    async function fetchJobsforCustomer(customerModel) {
      setIsLoading(false)
      if (!customerModel?.customerIdentifier) {
        console.log("No customer identifier found");
        return;
      }

      setIsLoading(true)
      try {
        const jobsApiCallResponse = await jobsRepository.getAllJobs(customerModel.customerIdentifier, startDate, endDate, jobStatusInputValue);
        setJobsData(jobsApiCallResponse)

        console.log("Jobs Data", jobsData)
        setIsLoading(false);



      } catch (error) {
        console.error("Api Call Data Error:", error);
        toast.error(new Error(`${error}`).message);
        setIsLoading(false);

      }
    }

    async function initializeData() {
      try {
        // First, fetch customer model and job status options
        const customerModel = await getCustomerModelFromLocalStorage();

        console.log("Customer Model when initializing is", customerModel)

        await fetchJobStatusOptions();

        // Then, fetch jobs for the customer once the above two are done
        await fetchJobsforCustomer(customerModel);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error initializing data:", error);
      }
    }

    initializeData();




  }, [competitionTableFilter]);



  return (
    <div>
      <h5 style={{ textAlign: 'left', margin: '0' }}>Your Customer Identifier is: {customer.customerIdentifier}</h5>
      <h2>Jobs</h2>
      <div>


        {/* Start Date Range Picker */}
        {/* <div>


          <button className="date-button" onClick={handleDateRangeButtonClick}>Select Date</button>

      

          {showPicker && (

            <DateRangePicker
              ranges={[dateRange]}
              onChange={handleDateRangeChange}
            />

          )}





          <p className="date-range-picker__selected-date-range">
            Selected Date Range: {startDate} - {endDate}
          </p>

          {showPicker && (<button className="date-button" style={{ marginBottom: '20px' }} onClick={handleDateRangeDoneClick}>Done</button>)}


        </div> */}



        {/* End Date Range Pciker */}




        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '50px' }}>






            <Select
              key={keyUsedToClearJobStatusSelect}

              placeholder={jobStatusPlaceholder}
              options={jobStatusOptions}
              onChange={handleJobStatusSelectedOptions}
              isSearchable={false}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  width: 300,
                }),
                option: (provided) => ({
                  ...provided,
                  textAlign: 'left',
                }),
              }}
            />





            {/* <button className="date-button" onClick={handleClear}>Clear Filter</button> */}




          </div>
        </div>


      </div>

      <div>

        <br></br>
        {isLoading && <div className="spinner"></div>}

        <br></br>



      </div>




      <ToastContainer />


      <div class="table-container" >

        {jobsData.length > 0 ? (


          <table className="table">
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Reference/Purchase Order Number</th>
                <th>Job Status</th>

                <th>Due Date</th>
                <th>Issued By</th>

                <th>Slung</th>
                <th>Bar Weight in Tonnes</th>
                <th>Notes</th>

                <th>Blueprints</th>



              </tr>
            </thead>



            <tbody>
              {jobsData.map((job, index) => (
                <tr key={job.dueDate}>
                  <td>{index + 1}</td>
                  <td>{job.referenceNumber}</td>
                  {/* <td>{participation.competition.competitionStatus}</td> */}

                  <td>{job.jobStatus}</td>
                  <td>{job.dueDate}</td>
                  <td className="highlight-column">{job.issuedBy}</td>

                  {/* <td className="highlight-column" onClick={() => handleCompetitorNameClicked(participation.competitor.competitorId)}>
                    {participation.competitor.competitorName}

                  </td> */}

                  <td>{job.slung}</td>
                  <td>{job.barWeight}</td>
                  <td>{job.notes}</td>
                  {/* <td>{job.blueprints}</td> */}
                  <td>
                    {job.blueprints.split('\n').map((blueprint, index) => (
                      <a
                        key={index}
                        href={blueprint}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ display: 'block', marginBottom: '4px' }}
                      >
                        {blueprint}
                      </a>
                    ))}
                  </td>




                </tr>
              ))}
            </tbody>

          </table>
        ) : (

          <table className="table">
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Reference/Purchase Order Number</th>
                <th>Job Status</th>

                <th>Due Date</th>
                <th>Issued By</th>

                <th>Slung</th>
                <th>Bar Weight in Tonnes</th>
                <th>Notes</th>

                <th>Blueprints</th>



              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="9" style={{ textAlign: 'center' }} >No data available. Please check your customer identifier or update the job status.</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>




    </div>
  );
}

export default Home;