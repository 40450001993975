import React, { useState } from "react";

import AuthRepository from '../../domain/repositories/AuthRepository';
import '../../assets/css/form.css';
import { LOGIN_MESSAGE } from '../../utils/constants/message_constants'

import { validateName } from '../../utils/validation';


// function LoginForm() {
const LoginForm = ({ onLogin }) => {

  const [customerIdentifier, setCustomerIdentifier] = useState("");
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const authRepository = new AuthRepository();


  const handleLogin = async (event) => {
    event.preventDefault();
    try {

      setIsSubmitting(true);
      setError("");
      const errors = [];

      if (!validateName(customerIdentifier)) {
        errors.push(LOGIN_MESSAGE.USERNAME_VALID);
      }

      if (errors.length > 0) {
        setError(errors);
        setIsSubmitting(false);
      } else {

        await authRepository.login(customerIdentifier)
        setError(`Login Success`);
        setIsSubmitting(false);
        setCustomerIdentifier('')
        onLogin();
      }

    } catch (error) {
      console.error(error);
      setError(`${error}`);
      setIsSubmitting(false);
    }
  };

  const handleLogout = () => {
    sessionStorage.removeItem("access_token");
  };

  const accessToken = sessionStorage.getItem("access_token");

  if (accessToken) {
    return (
      <div>
        <p>You are logged in.</p>
        <button onClick={handleLogout}>Logout</button>
      </div>
    );
  }

  return (
    <div>

      <h2>Concrete World Dubbo</h2>

      <form className="form-container" onSubmit={handleLogin}>
        <div className="form-group">
          <label>
            Please enter your customer identifier
            <input type="text" value={customerIdentifier} onChange={(e) => setCustomerIdentifier(e.target.value)} />
          </label>
        </div>



        <div className="form-group">

          <button type="submit">Enter</button>
        </div>

        {isSubmitting && <div className="spinner"></div>}

        {error && <p className="error-message ">{error}</p>}
      </form>
    </div>

  );
}

export default LoginForm;