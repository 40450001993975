import { TOKEN } from '../../utils/constants/api_constants';


const CUSTOMER_IDENTIFIER_KEY = 'customerIdentifier';
const CUSTOMER_NAME_KEY = 'customerName';
const CUSTOMER_EXISTS_KEY = 'customerExists';

class LocalStorageService {
  static serviceInstance = null;

  static getService() {
    if (!LocalStorageService.serviceInstance) {
      LocalStorageService.serviceInstance = new LocalStorageService();
    }
    return LocalStorageService.serviceInstance;
  }

  // setCustomerName(name) {
  //   localStorage.setItem(CUSTOMER_NAME_KEY, name);
  // }

  setCustomerIdentifier(customerIdentifier) {
    localStorage.setItem(CUSTOMER_IDENTIFIER_KEY, customerIdentifier);
  }

  // setCustomerExists(customerExists) {
  //   localStorage.setItem(CUSTOMER_EXISTS_KEY, customerExists);
  // }

  getCustomerIdentifier() {
    return localStorage.getItem(CUSTOMER_IDENTIFIER_KEY);
  }
  // getCustomerName() {
  //   return localStorage.getItem(CUSTOMER_NAME_KEY);
  // }

  // getCustomerExists() {
  //   return localStorage.getItem(CUSTOMER_EXISTS_KEY);
  // }


  removeCustomerIdentifier() {
    localStorage.removeItem(CUSTOMER_IDENTIFIER_KEY);
  }


  // removeCustomerName() {
  //   localStorage.removeItem(CUSTOMER_NAME_KEY);
  // }


  // removeCustomerExists() {
  //   localStorage.removeItem(CUSTOMER_EXISTS_KEY);
  // }
}

export default LocalStorageService;