
import apiClient from '../../api/apiClient';
import { API_ENDPOINTS_ITEMS, API_ENDPOINTS, API_FIELDS_PARTICIPATES_IN, API_FIELDS_STATUS, API_ENDPOINTS_OBJECT } from '../../utils/constants/api_constants';
import Mapper from '../models/mapper/Mappers';
import Params from '../models/params/FilterParams';
import FieldParams from '../models/params/FieldParams';
import { convertAuDateIntoApiDate } from '../../utils/dateUtils';



class JobsRepository {

    async getParticipatesInById(participatesInId) {
        const fields = FieldParams.fetchFieldsForParticipatesIn()
        const response = await apiClient.get(`${API_ENDPOINTS_ITEMS.PARTICIPATES_IN}/${participatesInId}?fields=${fields}`);
        const participatesInData = response.data;
        const participatesInById = Mapper.mapToParticipatesInModel(participatesInData);
        return participatesInById
    }

    async getAllJobStatuses() {
        const jobStatuses = [
            { value: "ALL", label: "ALL" },
            { value: "UPCOMING", label: "UPCOMING" },
            { value: "COMPLETE", label: "COMPLETE" },

            { value: "IN PROGRESS", label: "IN PROGRESS" },
            { value: "URGENT", label: "URGENT" },
            { value: "WELDING ONLY", label: "WELDING ONLY" },
            { value: "CANCELLED", label: "CANCELLED" },
            { value: "ON HOLD", label: "ON HOLD" },
            { value: "PROGRESS MADE", label: "PROGRESS MADE" }

        ];

        return jobStatuses
    }

    async getAllJobs(customerIdentifier, startDate, endDate, jobStatus) {
        console.log("customerIdentifier",customerIdentifier)

        const params = FieldParams.fetchJobsParams(customerIdentifier, startDate, endDate, jobStatus)
        // return {}

        const jobsData = await apiClient.get(`${API_ENDPOINTS.CUSTOMER_JOBS}?${params}`);

        // const jobsData = response.data;


        const jobsDataModel = []

        for (let i = 0; i < jobsData.length; i++) {
            jobsDataModel.push(Mapper.mapToJobsModel(jobsData[i]));
        }

        return jobsDataModel;
    }

    async getAllParticipatesIn() {

        const fields = FieldParams.fetchFieldsForParticipatesIn()
        const response = await apiClient.get(`${API_ENDPOINTS_ITEMS.PARTICIPATES_IN}?fields=${fields}`);
        const participatesInData = response.data;

        const participatesInDataModel = []

        for (let i = 0; i < participatesInData.length; i++) {
            participatesInDataModel.push(Mapper.mapToParticipatesInModel(participatesInData[i]));
        }

        return participatesInDataModel;
    }


    async getAllParticipatesInWithFilter(startDate, endDate, sectionId, competitorId, page, limit) {
        const startDateToSend = convertAuDateIntoApiDate(startDate)
        const endDateToSend = convertAuDateIntoApiDate(endDate)

        const filterParamsforParticipates = JSON.stringify(Params.fetchParticipatesInAllParams(startDateToSend, endDateToSend, sectionId, competitorId))

        const fields = FieldParams.fetchFieldsForParticipatesIn()
        const response = await apiClient.get(`${API_ENDPOINTS_ITEMS.PARTICIPATES_IN}?limit=${limit}&meta=filter_count&page=${page}&fields=${fields}&filter=${filterParamsforParticipates}&sort=order_no`);

        // const response = await apiClient.get(`${API_ENDPOINTS_ITEMS.PARTICIPATES_IN}?limit=${PAGE_LIMIT}&fields=${fields}&filter=${filterParamsforParticipates}`);
        const participatesInData = response.data;

        const participatesInDataModel = []

        for (let i = 0; i < participatesInData.length; i++) {
            participatesInDataModel.push(Mapper.mapToParticipatesInModel(participatesInData[i]));
        }

        const meta = response.meta;
        return {
            "filterCount": meta.filter_count,
            "participatesInDataModel": participatesInDataModel
        }
    }






    async updateParticipatesInRank(participatesIn, rank) {

        const participatesInId = participatesIn.participatesInId
        const updateParticipatesRankModel = { [API_FIELDS_PARTICIPATES_IN.RANK]: rank }

        const participatesRankModel = JSON.stringify(updateParticipatesRankModel);
        const response = await apiClient.patch(`${API_ENDPOINTS_ITEMS.PARTICIPATES_IN}/${participatesInId}`, updateParticipatesRankModel);
        const participateInData = response.data;

        const participateInDataModel = Mapper.mapToParticipatesWithoutField(participateInData);

        return participateInDataModel

    }



    async createParticipatesIn(participatesInModel) {

        const participatesInModelJsonObject = {
            [API_FIELDS_STATUS.STATUS]: API_FIELDS_STATUS.PUBLISHED,
            [API_ENDPOINTS_OBJECT.COMPETITION]: participatesInModel.competition,
            [API_ENDPOINTS_OBJECT.COMPETITOR]: participatesInModel.competitor,
            [API_FIELDS_PARTICIPATES_IN.ORDER_NO]: participatesInModel.orderNo,

        };
        console.log('participatesInModelJsonObject', participatesInModelJsonObject)


        const response = await apiClient.post(`${API_ENDPOINTS_ITEMS.PARTICIPATES_IN}`, participatesInModelJsonObject);
        const participatesInData = response.data;
        const participatesInModelCreate = Mapper.mapToParticipatesWithoutField(participatesInData);
        return participatesInModelCreate
    }

    async createParticipatesWithCompetitionAndCompetitorExistsCheck(participatesInModel) {
        let participatesInModelResponse = participatesInModel


        try {
            const participatesInData = await this.getParticipatesInByCompetitorAndCompetition(participatesInModel.competition, participatesInModel.competitor);
            console.log('particiaptesInData', participatesInData)

            if (participatesInData.length === 0) {
                participatesInModelResponse = await this.createParticipatesIn(participatesInModel);
                console.log('ParticipatesIn does not exist in database. Created ParticipatesIn now and got this response:', participatesInModelResponse);
            }
            else {
                participatesInModelResponse = participatesInData[0]
                console.log(`Participation Exists Database Order number: ${participatesInModelResponse.orderNo} exist in database. Competition Id is ${participatesInModelResponse.participatesInId}`);
            }


        }

        catch (error) {
            throw new Error(`Error while creating Participation: ${error}`);

        }

        return participatesInModelResponse
    }


}

export default JobsRepository;
