import { API_ENDPOINTS_OBJECT } from '../../../utils/constants/api_constants';


function fetchFieldsForParticipatesIn() {
    const star = "*"

    const competitor = API_ENDPOINTS_OBJECT.COMPETITOR + '.' + star

    const competition = API_ENDPOINTS_OBJECT.COMPETITION + '.' + star

    const competitionSection = API_ENDPOINTS_OBJECT.COMPETITION + '.' + API_ENDPOINTS_OBJECT.SECTION + '.' + star


    const participatesInFields = star + ',' + competitor + ',' + competition + ',' + competitionSection

    return participatesInFields

}


function fetchFieldsForSection() {
    const star = "*"

    const sectionFields = star

    return sectionFields

}

function fetchJobsParams(customerIdentifier, startDate, endDate, jobStatus) {
    // const jobFields = "customer_identifier=" + customerIdentifier + "&start_date=" + startDate + "&end_date=" + endDate + "&job_status=" + jobStatus
    if (jobStatus == '') {
        jobStatus = 'ALL'
    }
    const jobFields = "customer_identifier=" + customerIdentifier + "&job_status=" + jobStatus
    return jobFields
}

export default {
    fetchFieldsForParticipatesIn,
    fetchFieldsForSection,
    fetchJobsParams,
};