import apiClient from '../../api/apiClient';
import { API_ENDPOINTS_ITEMS, DEFAULT_PAGE_LIMIT } from '../../utils/constants/api_constants';
import Mapper from '../models/mapper/Mappers';
import Params from '../models/params/FilterParams';
import FieldParams from '../models/params/FieldParams';
import authService from '../../infrastucture/external/AuthService';

import Customer from '../models/CustomerModel';


class AuthRepository {

  async login(customerIdentifier) {
    return authService.login(customerIdentifier);
  }

  // async logout() {
  //   authService.logout();
  // }

  async isAuthenticated() {
    return authService.isAuthenticated();
  }

  async getCustomerIdentifier() {
    return authService.getCustomerIdentifier();
  }

  async setCustomerIdentifier(customerIdentifier) {
    authService.setCustomerIdentifier(customerIdentifier);
  }

  async getCustomerModelFromLocalStorage() {
    const customerIdentifier = authService.getCustomerIdentifier();
    // const customerName = authService.getCustomerName();
    // const customerExists = authService.getCustomerExists();
    return new Customer(null,customerIdentifier,null)
  }






  // async refreshAccessToken() {
  //   return authService.refreshAccessToken();
  // }



}

export default AuthRepository;