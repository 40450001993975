
class Jobs {
    constructor(referenceNumber = null, dueDate = null, jobStatus = null, issuedBy = null, slung = null, barWeight = null, notes = null, blueprints = null,customer_identifier=null) {
        this.referenceNumber = referenceNumber;
        this.dueDate = dueDate;
        this.jobStatus = jobStatus;
        this.issuedBy = issuedBy;
        this.slung = slung;
        this.barWeight = barWeight;
        this.notes = notes;
        this.blueprints = blueprints;
        this.customer_identifier=customer_identifier;

    }

}

export default Jobs;
