import { API_BASE_URL } from "../../utils/constants/api_constants";
import LocalStorageService from "../internal/LocalStorageService";
import apiClient from '../../api/apiClient';
import { API_ENDPOINTS, } from '../../utils/constants/api_constants';
import Mapper from '../../domain/models/mapper/Mappers';


const localStorageService = LocalStorageService.getService();

class AuthService {
  async login(customerIdentifier) {
    const customerIdentifierRequest = {
      "customer_identifier": customerIdentifier,
    }
    // const authenticationData = await apiClient.post(`${API_ENDPOINTS.CUSTOMER_AUTHENTICATE}`, customerIdentifierRequest);
    localStorageService.setCustomerIdentifier(customerIdentifier);


    // const customerModel = Mapper.mapToCustomerModel(authenticationData)
    // console.log("Customer Model: ", customerModel);


    // if (!customerModel.customerExists) {
    //   localStorageService.setCustomerExists(customerModel.customerExists);
    //   localStorageService.setCustomerIdentifier(customerIdentifier);
    //   throw new Error("Oops that is not the correct identifier. Please contact us at 02 6885 6876");
    // }


    // localStorageService.setCustomerIdentifier(customerIdentifier);
    // localStorageService.setCustomerName(customerModel.customerName);
    // localStorageService.setCustomerExists(customerModel.customerExists);
  }

  async logout() {
    localStorageService.removeCustomerIdentifier();
    // localStorageService.removeCustomerName();
    // localStorageService.removeCustomerExists();
  }

  // getCustomerName() {
  //   return localStorageService.getCustomerName();
  // }

  getCustomerIdentifier() {
    return localStorageService.getCustomerIdentifier();
  }

  // getCustomerExists() {
  //   return localStorageService.getCustomerExists();
  // }

  setCustomerIdentifier(customerIdentifier) {
    localStorageService.setCustomerIdentifier(customerIdentifier);
  }


  isAuthenticated() {
    const customerIdentifier = localStorageService.getCustomerIdentifier();
    if (customerIdentifier && customerIdentifier !== null) {
      return true;
    }
    return false;
  }


}

const authService = new AuthService();
export default authService;

// export default new AuthService();
