
class Customer {
    constructor(customerName = null, customerIdentifier = null, customerExists = null) {
        this.customerName = customerName;
        this.customerIdentifier = customerIdentifier;
        this.customerExists = customerExists

    }

}

export default Customer;
