import React from "react";
import { Link } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../../assets/css/navbar.css";
import logo from "../../assets/images/logo.png"

class Navbar extends React.Component {
  render() {
    const { isAuthenticated } = this.props;
    const { onLogout } = this.props;


    return (
      <nav className="navbar">
        <div>
          <img
            src={logo}
            alt="Logo"
          />
        </div>

        <ul>

          <li>
            <Link to="/">Home</Link>{" "}
          </li>

          <li>
            <a href="https://form.jotform.com/242740773515862" target="_blank" rel="noopener noreferrer">Form</a>
          </li>


          <li onClick={onLogout}>
            <Link to="/login">Change-Customer</Link>
          </li>

          {/* <li>
            <Link to="/competition">Competition</Link>
          </li> */}

          {/* <li>
            <Link to="/add/competitor">Competitor</Link>
          </li>
          <li>
            <Link to="/add
            /section">Section</Link>
          </li> */}
          {/* 
{!isAuthenticated&& (
                  <li>
                    <Link to="/login">Login</Link>
                  </li>
                  
                )} */}

          {/* <li>
            <Link to="/login">Login</Link>
          </li> */}
          {/* 
      {isAuthenticated&& (
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  
                )} */}

          {/* <li>
            <Link to="/">Home</Link>
          </li> */}
          {/* {isAuthenticated&& (

          <li>
            <Link to="/upload/csv">Upload</Link>
          </li>
          )}

{isAuthenticated&& (
         
          <li>
            <Link to="/logout">Logout</Link>
          </li>
          )} */}

          {/* <li>
            <a href="#">
              <i className="fas fa-user"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fas fa-sign-in-alt"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fas fa-sign-out-alt"></i>
            </a>
          </li> */}
        </ul>
      </nav>
    );
  }
}

export default Navbar;
